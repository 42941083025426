.categoryProduct{
    width: 345px;
    height: 112px;
    flex-shrink: 0;
    background: #252525;
    /* bana #F7C048 renk koduna sahip bir kontur ver inside olsun*/
    border: 1.5px solid #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.category-item-img{
    width: 112px;
    height: 112px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    object-fit: cover;
}
.category-item-lower{
    /* bana item-lower i img in saginda olacak sekilde ayarla */
    width: 233px;
    display: flex;
    align-items: left;
    flex-direction: column;
    padding: 12px;
}
.category-item-title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-weight: bold;
    text-align: left;
    color: white;
    padding-bottom: 6px;
}

.category-item-description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    width: auto;
    height: 40px;
    max-width: 26ch;
    font-weight: 300;
    text-align: left;
    color: white;
}
.category-item-price{
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
}
