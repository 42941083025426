.recommendedProductDiv{
    background-color: #FFF;
    /* stick to bottom; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.categoryTittle2{
    padding-top: 24px;
    padding-left: 27px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.recommendedProduct{
    display: flex;
    overflow: auto;
    width: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
}

.recommendedProduct .product-home-rec{
    margin-right: 10px;
}


::-webkit-scrollbar{
    width: 1px;
}

::-webkit-scrollbar-track{
    background-color: none;
}