.footer{
    position: fixed;
    color: white;
    background-color: rgb(29, 29, 29);
    width: 100%;
    text-align: center;
    bottom: 0;
}

.footerContent{
    font-weight: bold;
}

.footerContent2{
    font-weight: bold;
    font-size: 10px;
}