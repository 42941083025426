.product-home-rec{
    display: flex;
    flex-direction: column;
    width: 359px;
    height: auto;
    min-height: 264px;
    background: #ededed;
    border-radius: 15px;
}

.product-item-img{
    display: block;
    width: 359px;
    height: 152px;
    border-bottom: 3px solid #000000;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
}
.product-item-lower{
    display: flex;
    flex: 2;
    flex-direction: column;
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
}
.product-item-title{
    display: block;
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: 600;
    font-weight: bold;
    text-align: left;
    color: #303030;
}
.product-item-description{
    display: block;
    margin-bottom: 6px;
    width: 100%;
    height: auto;
    min-height: 27px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #303030;
}
.product-item-alt-kisim{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    /* background-color: red; */
}
.product-item-time-div{
    display: flex;
    flex-direction: row;
    margin-right: 12px;
}

.product-item-time-icon{
    margin-right: 6px;
}
.product-item-time-text{
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-weight: 400;
    text-align: left;
    color: #303030;
}

.product-item-price{
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-weight: 500;
    text-align: left;
    color: #303030;
}