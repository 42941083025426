.categoryWidget {
    min-width: 150px;
    height: 194px;
    background: rgb(29, 29, 29);
    border-radius: 22px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.308));
}

.categoryImg {
    display: block;
    width: 150px;
    height: 150px;
    /* border-bottom: 2px solid #F7C048; */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
}

.categoryTitle {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;    
    color: white;
    text-align: center;
}

.categoryAngle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.categoryAngle .categoryAngleIcon {
    width: 26px;
    height: 26px;
}


.categoryWidget:hover {
    background-color: #aeaeae;
    color: #ffffff;

}
.categoryWidget:hover > .categoryAngle{
    background-color: #ffffff;

}

.categoryWidget:hover > .categoryTitle{
    color: #ffffff;
}
.categoryWidget:hover > .categoryAngleIcon{
    color: white !important;
}