.categoryRenderer{
    display: flex;
    overflow: auto;
    width: auto;
}
.categoryRenderer .categoryWidget{
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}


::-webkit-scrollbar{
    width: 1px;
}

::-webkit-scrollbar-track{
    background-color: none;
}