.login{
    width: 100vm;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper{
    width: 70%;
    height: 70%;
    display: flex;
}

.loginLeft, .loginRight{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loginLogo{
    width: 366px;
    height: auto;      
}

@media only screen and (max-width: 1280px){
    .loginWrapper{
        width: 70%;
        height: 70%;
        display: inline;
    }
    .loginLeft{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loginRight{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


/* .loginBox{
    height: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

/* .loginInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
}

.loginInput:focus{
    outline: none;
}

.loginButton{
    height: 66px;
    width: 351px;
    border-radius: 16px;
    border: none;
    background-color: #A939FE;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.loginButton:disabled{
    cursor: not-allowed;
} */