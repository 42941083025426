.sub_category, .categoryFirst{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.categoryFirst .categoryProduct{
    margin-bottom: 10px;
}
.sub_category{
    margin-top: 10px;
}
.sub_category .categoryProduct{
    margin-bottom: 12px;
}
.category-item-name, .sub_category_name{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    color: white;
}
