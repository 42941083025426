.header{
    height: auto
}

.topPhoto{
    display: block;
    padding: 30px;
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    /* border: 5px solid #ffffff; */
}

.headerTittle{
    background-color: #A939FE;
    color : #ffffff;
    text-align: center;
    font-weight: 600;
}